import React from "react";
import propTypes from "prop-types";
import { motion } from "framer-motion";
import { container } from "./Loading.module.css";

const loadingVariants = {
  hidden: { scale: 0.7, opacity: 0 },
  show: { scale: 1, opacity: 1 },
};

const LOADER_SIZE = 100;

Loader.propTypes = {
  size: propTypes.number,
  alt: propTypes.bool,
  className: propTypes.string,
};
export function Loader(props) {
  const { alt = false, size = LOADER_SIZE, ...rest } = props;
  const imgUrl = alt
    ? require("./images/loading-alt.svg")
    : require("./images/loading.svg");

  return (
    <motion.img
      {...rest}
      initial="hidden"
      exit="hidden"
      animate="show"
      className={props.className}
      width={size}
      height={size}
      variants={loadingVariants}
      src={imgUrl}
    />
  );
}

export default function LoadingComponent(props) {
  return (
    <div className={container}>
      <Loader {...props} />
    </div>
  );
}
