import React from "react";
import Link from "next/link";
import { signOut, useSession } from "next-auth/client";

import { H6 } from "@andertons/design-system/components/Text.jsx";
import { Home, AddSolid, Film, Layers, LockClosed } from "react-zondicons";
import HeaderUI, {
  MenuItem,
  MenuItemDivider,
} from "@andertons/design-system/components/Header.jsx";

export default function Header() {
  const [{ user = {} }] = useSession();
  const { email = "" } = user;
  return (
    <HeaderUI onLogOut={signOut} user={user}>
      <MenuItemDivider>Navigation</MenuItemDivider>
      <MenuItem Icon={(props) => <Home {...props} />}>
        <Link href="/">
          <a data-cy="sidebar-nav-home">
            <H6 as="span" classNames="text-gray-700">
              Home
            </H6>
          </a>
        </Link>
      </MenuItem>

      <MenuItem Icon={(props) => <LockClosed {...props} />}>
        <Link href="/privacy">
          <a data-cy="sidebar-nav-privacy">
            <H6 as="span" classNames="text-gray-700">
              Privacy Policy
            </H6>
          </a>
        </Link>
      </MenuItem>

      <MenuItemDivider>Videos</MenuItemDivider>

      <MenuItem Icon={(props) => <AddSolid {...props} />}>
        <Link href="/video/new" passHref>
          <a data-cy="sidebar-nav-new-video">
            <H6 as="span" classNames="text-gray-700">
              Create a Video
            </H6>
          </a>
        </Link>
      </MenuItem>

      <MenuItem Icon={(props) => <Film {...props} />}>
        <Link
          href={{ pathname: "/videos", query: { authorId: email } }}
          passHref
        >
          <a data-cy="sidebar-nav-my-videos">
            <H6 as="span" classNames="text-gray-700">
              My Videos
            </H6>
          </a>
        </Link>
      </MenuItem>

      <MenuItem Icon={(props) => <Layers {...props} />}>
        <Link href="/videos" passHref>
          <a data-cy="sidebar-nav-all-videos">
            <H6 as="span" classNames="text-gray-700">
              All Videos
            </H6>
          </a>
        </Link>
      </MenuItem>

    </HeaderUI>
  );
}
